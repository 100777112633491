import React, { useEffect } from "react";
import { Col, Row, Button } from "react-bootstrap";
import NewWindowOpen from "utils/NewWindowOpen";
import "./index.scss";
import AOS from "aos";
import { useHistory } from "react-router";
export const Hero = () => {
  const history = useHistory();
  useEffect(() => {
    AOS.init({
      duration: 2000,
      mirror: true,
    });
  }, []);

  return (
    <div className="hero">
      <div className="component">
        <div className="inner-wrap">
          <div className="slide slide1">
            <div className="content">
              <Row>
                <Col
                  lg={12}
                  className="hero__title"
                  data-aos="fade-up"
                  data-aos-duration="500"
                >
                  <h1>Built the Business, But Not the Brand?</h1>
                </Col>
              </Row>
              <Row>
                <Col
                  sm={12}
                  className="hero__sub"
                  data-aos="fade-up"
                  data-aos-duration="700"
                >
                  <div className="sub">
                  We transform scattered branding into cohesive strategy by aligning your message, design, and digital experience so your business can grow with confidence.
                  </div>
                </Col>
              </Row>
              <Row>
                <Col
                  lg={12}
                  className="hero__btn-wrap"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  <Button
                    onClick={() => history.push("/contact-us")}
                    variant="outline-light"
                    className="mbd-btn-white-bg"
                  >
                    Let’s get started
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
