/* eslint-disable react/no-unknown-property */
import React, {
  useEffect,
  // useMemo,
  useState,
  useRef,
} from "react";
import AOS from "aos";
import "./Services.scss";
import NewWindowOpen from "utils/NewWindowOpen";
import Copywriting from "resources/images/png/avatar-a-99x99.png";
import CopywritingWebp from "resources/images/webp/avatar-a-99x99.webp";

import GraphicArtDesign from "resources/images/png/avatar-b-99x99.png";
import GraphicArtDesignWebp from "resources/images/webp/avatar-b-99x99.webp";

import OnlineStoreManagement from "resources/images/png/avatar-c-99x99.png";
import OnlineStoreManagementWebp from "resources/images/webp/avatar-c-99x99.webp";

import BizBrandingRebranding from "resources/images/png/avatar-d-99x99.png";
import BizBrandingRebrandingWebp from "resources/images/webp/avatar-d-99x99.webp";

import SocialMediaMarketing from "resources/images/png/avatar-e-99x99.png";
import SocialMediaMarketingWebp from "resources/images/webp/avatar-e-99x99.webp";

import WebDesignFrontendDevelopment from "resources/images/png/avatar-f-99x99.png";
import WebDesignFrontendDevelopmentWebp from "resources/images/webp/avatar-f-99x99.webp";

//import Tangram from "resources/images/png/tangram-design-and-print-99x99.png";
//import TangramWebp from "resources/images/webp/tangram-design-and-print-99x99.webp";

import { useSpring, animated, config } from "react-spring";

export const Services = () => {
  useEffect(() => {
    AOS.init({
      duration: 900,
      once: true,
    });
  }, []);
  const servicesList = [
    {
      title: "Brand Strategy & Identity",
      content:
        "Clarify your brand from the ground up. We help you shape your message, visuals, and positioning so your business stands out, connects, and grows. Perfect if you’re starting out or stuck in the wrong look.",
      image: BizBrandingRebranding,
      webp: BizBrandingRebrandingWebp,
      imgDesc:
        "Smiling man holding a results chart with thumbs up, representing marketing success and lead generation",
      link: "https://martleystrategy.com/contact-us",
    },
    {
      title: "Marketing & Lead Generation",
      content:
        "No more guesswork. We create strategies, funnels, and campaigns that attract your ideal customers and turn them into loyal buyers. Great for businesses ready to grow but missing a clear path.",
      image: SocialMediaMarketing,
      webp: SocialMediaMarketingWebp,
      imgDesc:
        "Hand clicking a red mouse with folders above, symbolizing campaign execution and online store updates",
      link: "https://martleystrategy.com/contact-us",
    },
    {
      title: "Graphic Design & Copywriting",
      content:
        "Need consistent content that looks sharp and sounds right? From social graphics to campaign messaging, pitch decks to sales materials, we craft assets that bring your brand to life and move people to action.",
      image: GraphicArtDesign,
      webp: GraphicArtDesignWebp,
      imgDesc:
        "Overhead illustration of a person writing at a desk surrounded by creative project sheets and design materials",
      link: "https://martleystrategy.com/contact-us",
    },
    {
      title: "Web, App & System Development",
      content:
        "We design and build high-performing websites, apps, and backend systems that grow with your business. If your current setup feels clunky or limited, we’ll create a smarter experience built to scale.",
      image: Copywriting,
      webp: CopywritingWebp,
      imgDesc:
        "Illustration of a person using a red laptop and mouse, representing software and digital work setup",
      link: "https://martleystrategy.com/contact-us",
    },
    {
      title: "Online Store Management",
      content:
        "We keep your e-commerce moving smoothly. From campaign planning and product uploads to seasonal updates and merchandising, we handle the backend so you can focus on the bigger picture.",
      image: OnlineStoreManagement,
      webp: OnlineStoreManagementWebp,
      imgDesc:
        "Abstract icon of a digital dashboard with interconnected folders, symbolizing e-commerce workflow",
      link: "https://martleystrategy.com/contact-us",
    },
    
    {
      title: "Special Projects",
      content:
        "Got a creative idea that doesn’t fit the usual mold? We take on custom requests like product concepts, packaging, visual prototypes, and unique brand executions that set you apart.",
      image: WebDesignFrontendDevelopment,
      webp: WebDesignFrontendDevelopmentWebp,
      imgDesc:
        "Man with headset smiling, representing customer service and special project consultation",
      link: "https://martleystrategy.com/contact-us",
    },
  ];  
  
  const [, forceUpdate] = useState();
  const [services] = useState([...servicesList]);

  const serviceRef = useRef();
  const servicesRef = useRef();

  // false = 'See More'
  // true = 'See Less'
  const [seeMoreServicesToggle, setSeeMoreServicesToggle] = useState(false);

  const servicesStyle = useSpring({
    height: seeMoreServicesToggle
      ? serviceRef?.current?.offsetHeight * servicesList.length
      : serviceRef?.current?.offsetHeight * 3,
    config: config.gentle,
  });

  useEffect(() => {
    forceUpdate();
    setSeeMoreServicesToggle(!seeMoreServicesToggle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggleShowMoreOrLess = () => {
    setSeeMoreServicesToggle(!seeMoreServicesToggle);
  };

  return (
    <>
      <animated.div
        className="services"
        style={servicesStyle}
        ref={servicesRef}
      >
        {services.map((e, id) => {
          const { title, content, image } = e;
          const aosDuration = (id + 1) * 100 + 1000;

          return (
            <div
              key={id}
              className="service"
              ref={serviceRef}
              data-aos="fade-up"
              data-aos-offset="50"
              data-aos-duration={aosDuration}
            >
              <div className="icon">
                <picture>
                  <source srcSet={image.webp} type="image/webp" />
                  <img
                    src={image}
                    alt={image.title}
                    desciption={image.imgDesc}
                  />
                </picture>
              </div>
              <div className="details">
                <div className="details__texts">
                  <div className="details__texts__title">
                    <h4>{title}</h4>
                  </div>
                  <div className="details__texts__content">
                    <div className="details__texts__content__inner-wrap">
                      {content}
                    </div>
                    <div className="btn-wrap">
                      <button
                        className="btn__service"
                        onClick={() => NewWindowOpen(e.link)}
                      >
                        Start project
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </animated.div>

      {/* <div
        className="services-text-toggle"
        onClick={handleToggleShowMoreOrLess}
      >
        {!seeMoreServicesToggle ? "See more services" : "See less"}
      </div> */}
    </>
  );
};
