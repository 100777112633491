import React from "react";
// import {useState} from "react";
// import {useState, useEffect} from 'react';
import "./Kebab.scss";
import CloseIcon from "@material-ui/icons/Close";
import { HashLink as Link } from "react-router-hash-link";
import { Socmed } from "components/_templates";

// import {Link} from "react-router-dom";
import { useStateSelector, useActionDispatch } from "../Menu/hooks";
import { animated, useSpring, config } from "react-spring";
// import { lighten } from "@material-ui/core";

const Kebab = () => {
  const { kebab } = useStateSelector();
  const { handle_kebab } = useActionDispatch();

  const handleKebab = () => {
    handle_kebab(false);
  };
  // const useTransStyles = useTransition();
  const styles = useSpring({
    x: kebab ? 0 : 100,
    opacity: kebab ? 1 : 0,
    pointerEvents: kebab ? "all" : "none",
    config: config.gentle,
  });

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -180;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  const links = [
    /*{
      to: "#offers",
      label: "Offers",
    },*/
    {
      to: "#our-services",
      label: "Our Services",
    },
    {
      to: "#reviews",
      label: "Reviews",
    },
    {
      to: "#featured",
      label: "Featured Project",
    },
    {
      to: "#subscribe",
      label: "Subscribe",
    },
    {
      to: "#about",
      label: "About",
    },
    {
      to: "contact-us",
      label: "Contact us",
    },
  ];

  return (
    <>
      {
        <animated.div
          onClick={handleKebab}
          className="kebab-wrap"
          style={styles}
        >
          <div
            className="kebab-bg-body"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="inner-wrap">
              <div className="link-wrap">
                {links.map((link, id) => {
                  return (
                    <Link
                      key={id}
                      scroll={scrollWithOffset}
                      smooth={(el) =>
                        el.scrollIntoView({
                          behavior: "auto",
                          block: "end",
                          offset: 100,
                        })
                      }
                      to={`/${link.to}`}
                      onClick={handleKebab}
                    >
                      {link.label}
                    </Link>
                  );
                })}
              </div>

              <div className="kebab-socialmedia-wrap">
                <Socmed />
              </div>

              <div className="kebab-copy">
                <h4>© Martley Strategy™. All Rights Reserved. The Martley Strategy™ name and logo are trademarks of Martley Strategy. Unauthorized use, reproduction, or distribution is strictly prohibited.</h4>
              </div>
            </div>

            <div className="kebab-close" onClick={handleKebab}>
              <CloseIcon fontSize="large" />
            </div>
          </div>
        </animated.div>
      }
    </>
  );
};

export default Kebab;
