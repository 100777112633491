import React from "react";

import NewWindowOpen from "utils/NewWindowOpen";
import { useEffect } from "react";
import AOS from "aos";
import { Button } from "react-bootstrap";
import "./NewsOffers.scss";
import { useHistory } from "react-router";

export const NewsOffers = () => {
  const history = useHistory();
  useEffect(() => {
    AOS.init({
      duration: 2000,
      mirror: true,
    });
  }, []);

  return (
    <div id="subscribe" className="newsOffers" title="NewsOffers">
      <div
        className="component"
        data-aos="fade-up"
        data-aos-offset="-200"
        data-aos-duration="700"
      >
        <div className="title-wrap show-below-767">
          <SectionTitle />
        </div>

        <div className="content-wrap">
          <div className="left">
            <div className="title-wrap show-above-767">
              <SectionTitle />
            </div>
            {/* <p>We&apos;ll</p> */}
            <p>Share your details and we’ll send helpful updates, offers and ideas as you decide.</p>
          </div>
          <div className="right">
            <div className="btn-wrap">
              <Button onClick={() => history.push("/sign-up")}>
                Subscribe
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SectionTitle = () => {
  return (
    <div className="title-wrap">
      <h2>Almost ready?</h2>
    </div>
  );
};
