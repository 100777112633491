import React, { useEffect } from "react";
import { TitleGeneric } from "..";
import { Services } from "./Services/Services";
import "./CoreServices.scss";
import AOS from "aos";

export const CoreServices = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
      mirror: true,
      once: true,
    });
  }, []);

  return (
    <div id="our-services" className="core-services">
      <div className="component">
        <div
          className="inner-wrap title"
          data-aos="fade-up"
          data-aos-offset="50"
          data-aos-duration="700"
        >
          <TitleGeneric
            segment1="Our "
            segment2="Services"
            weight="bold"
            alignment="center"
          />
        </div>
        <div style={{ position: "relative" }} className="inner-wrap title">
          <Services />
        </div>
      </div>
    </div>
  );
};
