import React, { useEffect } from "react";
import AOS from "aos";
import "./index.scss";

export const MartleyNutshell2 = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
      mirror: true,
    });
  }, []);
  return (
    <div className="martleyNutshell2">
      <div className="component">
        <div
          className="inner-wrap"
          data-aos="fade-up"
          data-aos-offset="-200"
          data-aos-duration="700"
        >
          <div className="text">
            <h4>
              Martley Strategy™ is a premier business consultancy focused
              on making your brand unforgettable. Specializing in branding,
              marketing, design, and technology solutions, we develop tailored
              strategies that elevate your business and create lasting
              connections with your audience. Whether you’re a startup aiming to
              establish a presence or an established business seeking a brand
              refresh, we provide the tools and insights needed to stand out in
              today’s competitive landscape.
              <br />
              <br />
              Our journey began with humble roots, connecting with small
              businesses and gaining a deep understanding of their unique
              challenges. This experience shaped our approach, enabling us to
              understand the core essence of every business and where they
              aspire to be. We believe a strong brand is the foundation of
              success, and we work closely with each client to craft strategies
              that resonate with their audience and reflect their values.
              <br />
              <br />
              Our digital marketing expertise ensures your brand reaches the
              right people, in the right places, at the right time.
              Additionally, our web and app development services enhance
              customer experience, helping you engage effectively with your
              audience across platforms. From high-converting websites to
              user-friendly apps, we tailor solutions that align with your
              business goals.
              <br />
              <br />
              What sets us apart is our global talent network. In addition to
              our core team, we collaborate with skilled professionals from
              around the world, enabling us to deliver impactful services no
              matter where our clients are based. This global reach keeps us at
              the forefront of industry trends, allowing us to provide
              innovative, diverse solutions that drive results.
              <br />
              <br />
              With strategic insights, bold solutions, and a commitment to
              excellence, let us guide your business toward sustainable growth
              and an unforgettable brand presence.
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};
