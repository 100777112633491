import React, { useEffect } from "react";

import NewWindowOpen from "utils/NewWindowOpen";
import { Image } from "react-bootstrap";

import { VoucherSlide } from "./VoucherSlide/VoucherSlide";

import ZombieHolidayJpg from "resources/images/jpeg/zombie-holiday-nft-470x168.jpg";
import ZombieHolidayWebp from "resources/images/webp/zombie-holiday-nft-470x168.webp";

import MBDWebServicesPNG from "resources/images/png/mbd-web-services.png";
import MBDWebServicesWebp from "resources/images/webp/mbd-web-services.webp";

import StrategyAndDesignJpg from "resources/images/jpeg/strategy-and-design.jpg";
import StrategyAndDesignWebp from "resources/images/webp/strategy-and-design.webp";

import MatoesMBDJpg from "resources/images/jpeg/matoes-mbd-2022.jpg";
import MatoesMBDWebp from "resources/images/webp/matoes-mbd-2022.webp";

import "./Vouchers.scss";
import AOS from "aos";

export const Vouchers = () => {
  useEffect(() => {
    AOS.init({
      duration: 900,
      mirror: true,
    });
  }, []);

  const images = [
    {
      alt: "Strategy and design",
      desc: "A woman wearing brown long sleeve setting up her shoe product for online store",
      dataAos: "fade-up-left",
      dataAosDuration: "800",
      webp: StrategyAndDesignWebp,
      fallOut: StrategyAndDesignJpg,
      link: "https://martleystrategy.com/contact-us",
    },
    {
      alt: "MBD Web Development Service Video",
      desc: "A man wearing black shirt with curly hair, smiling, with promotions pertaining to web development service",
      dataAos: "fade-up-right",
      dataAosDuration: "800",
      webp: MBDWebServicesWebp,
      fallOut: MBDWebServicesPNG,
      link: "https://youtu.be/Wwp8gejrWa0",
    },
    {
      alt: "Zombie Holiday NFT",
      desc: "Purple zombie wearing a black band sando, with a stylish pink hair and tongue out on a purple background",
      dataAos: "fade-up-left",
      dataAosDuration: "800",
      webp: ZombieHolidayWebp,
      fallOut: ZombieHolidayJpg,
      link: "https://zombieholidaynft.com/",
    },
    {
      alt: "MBD Website Development Services",
      desc: "Fresh tomatoes on black texture background, with texts pertaining to web development service at 10% off",
      dataAos: "fade-up-left",
      dataAosDuration: "800",
      webp: MatoesMBDWebp,
      fallOut: MatoesMBDJpg,
      link: "https://martleystrategy.com/contact-us",
    },
  ];

  return (
    <div id="offers" className="vouchers">
      <div className="component" data-aos="fade-up" data-aos-duration="1000">
        {/* hide when vport is 0px - 767px */}
        <div className="vouchers__inner-wrap static-images hide__to-md">
          {images.map((img, id) => (
            <div
              key={id}
              className="img-wrap"
              onClick={() => NewWindowOpen(img.link)}
            >
              <picture>
                <source srcSet={img.webp} type="image/webp" />
                <Image
                  fluid
                  src={img.fallOut}
                  description={img.desc}
                  alt={img.alt}
                  data-aos={img.dataAos}
                  data-aos-duration={img.dataAosDuration}
                />
              </picture>
            </div>
          ))}
        </div>

        <div className="vouchers__inner-wrap show__to-md hide__from-lg">
          {<VoucherSlide data={images} />}
        </div>
      </div>
    </div>
  );
};
