import { Contact } from "components/_pages";
import Success from "components/_pages/Contact/Success";
import Subscribe from "components/_pages/Subscribe";

import SubscriptionSuccess from "components/_pages/Subscribe/SubscriptionSuccess";

import React from "react";

const Homepage = React.lazy(() => import(`components/_pages/Homepage`));
const AboutUs = React.lazy(() => import(`components/_pages/AboutUs`));
// const UnderDev = React.lazy(()=>import(`components/_pages/UnderDev`));

export const meta = {
  home: [
    {
      name: "description",
      content:
        "Outgrown the DIY look? We help you clarify your brand and elevate your presence with strategy, design and digital solutions that spark real growth.",
    },
    {
      name: "keywords",
      content:
        "branding consultant, brand identity specialist, brand strategy consultant, digital marketing services, fashion branding, graphic design services, marketing consultancy, marketing strategies, real estate branding, technology solutions, business consultancy, business marketing, holistic wellness branding, organic beauty branding, wellness marketing strategies",
    },
  ],
  contactUs: [
    {
      name: "description",
      content:
        "Let us co-create your business. Discuss opportunities and identify strategies that will work",
    },
    {
      name: "keywords",
      content: "",
    },
  ],
  subscribe: [
    {
      name: "description",
      content:
        "Stay on our radar for insights, exclusive discounts, monthly offers, and more",
    },
    {
      name: "keywords",
      content: "",
    },
  ],
};

export const title = {
  home: "Martley Strategy™ | Build a Memorable Brand",
  contactUs: "Contact Us",
  subscribe: "Sign Up",
};

export const ROUTES_CONFIG = [
  {
    path: "/",
    exact: true,
    title: title.home,
    meta: meta.home,
    component: <Homepage />,
  },
  {
    path: "/home",
    component: <Homepage />,
  },
  {
    path: "/offers",
    component: <Homepage />,
  },
  {
    path: "/feedbacks",
    component: <Homepage />,
  },
  {
    path: "/about",
    component: <AboutUs />,
  },
  {
    path: "/contact-us",
    title: title.contactUs,
    meta: meta.contactUs,
    component: <Contact />,
  },
  {
    path: "/message-sent",
    title: title.contactUs,
    meta: meta.home,
    component: (
      <Contact>
        <Success />
      </Contact>
    ),
  },
  {
    path: "/sign-up",
    title: title.subscribe,
    meta: meta.subscribe,
    component: <Subscribe />,
  },
  {
    path: "/subscribed",
    title: title.contactUs,
    meta: meta.home,
    component: (
      <Subscribe>
        <SubscriptionSuccess />
      </Subscribe>
    ),
  },
  {
    component: <h1>404</h1>,
  },
];
