import React, { useState, useEffect } from "react";
import "./index.scss";
import { Modal } from "react-bootstrap";
import ReactPlayer from "react-player";
import AOS from "aos";

export const MBDClassic = () => {
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  useEffect(() => {
    AOS.init({
      duration: 2000,
      mirror: true,
    });
  }, []);

  return (
    <div className="MBDClassic">
      <div className="component">
        <div
          className="inner-wrap"
          data-aos="zoom-in-up"
          data-aos-offset="-200"
          data-aos-duration="600"
        >
          <div className="textWrap">
            <h2>
              Client <span>Spotlight</span>{" "}
            </h2>
            <h3>Discover how co-creation helped turn <strong>purpose into momentum.</strong></h3>
          </div>
          <div className="playButton" onClick={handleShowModal}>
            <svg
              id="Layer_1"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1000 1000"
            >
              <circle cx="500" cy="500" r="500" style={{ fill: `#fff` }} />
              <polygon points="387.12 751.38 698.72 500 387.12 248.63 387.12 751.38" />
            </svg>
          </div>
        </div>
      </div>

      <MbdModal show={showModal} centered onHide={handleCloseModal} />
    </div>
  );
};

const MbdModal = (props) => {
  const { onHide } = props;
  return (
    <Modal {...props} className="mbd-modal">
      <Modal.Header>
        <div className="close-btn" onClick={onHide}>
          <svg
            width="18"
            height="17"
            viewBox="0 0 18 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.1165 16.8212C15.7826 16.8212 15.4503 16.6934 15.1964 16.4395L1.19953 2.44432C0.691791 1.93658 0.691791 1.11193 1.19953 0.602484C1.70727 0.0947448 2.53192 0.0947448 3.04136 0.602484L17.0383 14.5994C17.546 15.1071 17.546 15.9318 17.0383 16.4412C16.7827 16.6951 16.4505 16.8212 16.1165 16.8212Z"
              fill="white"
            />
          </svg>
          <svg
            width="18"
            height="17"
            viewBox="0 0 18 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.12057 16.8185C1.78662 16.8185 1.45438 16.6907 1.20051 16.4368C0.692767 15.9291 0.692767 15.1044 1.20051 14.595L15.1957 0.599768C15.7035 0.0920287 16.5281 0.0920287 17.0376 0.599768C17.5453 1.10751 17.5453 1.93216 17.0376 2.4416L3.04064 16.4385C2.78677 16.6924 2.45282 16.8185 2.12057 16.8185Z"
              fill="white"
            />
          </svg>
        </div>
      </Modal.Header>
      <Modal.Body>
        <ReactPlayer
          controls
          width="560"
          height="315"
          playing
          className="react-player"
          url="https://youtu.be/gEjNV8_xxhQ"
        />

        <div className="youtube-contact-invite">
          <div className="content-wrap">
            <h2>Feeling inspired?</h2>
            <h3>Let&apos;s explore what&apos;s possible for your brand.</h3>
          </div>
          <div className="btn-wrap">
            <a
              href="https://martleystrategy.com/contact-us"
              className="btn btn-danger"
              target="_blank"
              rel="noreferrer"
            >
              Start now
            </a>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="subscription">
          <a
            href="https://www.youtube.com/watch?v=Wwp8gejrWa0"
            target="_blank"
            rel="noreferrer"
          >
            <span>Ready to grow online? Watch how.</span>
            <svg
              width="40"
              height="28"
              viewBox="0 0 40 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30.756 27.408H9.24399C4.61599 27.408 0.85199 23.648 0.85199 19.016V9.00402C0.85199 4.35602 4.61199 0.59202 9.24399 0.59202H30.752C35.38 0.59202 39.144 4.35202 39.144 9.00402V19.016C39.144 23.644 35.384 27.408 30.756 27.408ZM26.512 13.268L16.864 8.46402C16.48 8.29202 15.204 8.54002 15.204 8.98402V18.764C15.204 19.188 16.496 19.476 16.884 19.264L26.104 14.248C26.492 14.02 26.896 13.46 26.512 13.268Z"
                fill="white"
              />
            </svg>
          </a>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
