/* eslint-disable react/no-deprecated */
import React from "react";
import { createRoot } from "react-dom/client";
import * as serviceWorker from "./serviceWorker";
import { App } from "./container";
// import "resources/styles/scss/main.scss";
import { Provider } from "react-redux";
import { configuredStore } from "./store/index";
import "../src/resources/styles/scss/main.scss";
//import { CookiePolicy } from "components/_ui";
// import 'swiper/css/swiper.css';

export const store = configuredStore();
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <App />
    {/* <CookiePolicy /> */}
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


// testing shit
