import React, { useEffect } from "react";
import AOS from "aos";
import "./index.scss";

export const MartleyNutshell = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
      mirror: true,
    });
  }, []);

  return (
    <div id="about" className="martleyNutshell">
      <div className="component">
        <div
          className="inner-wrap"
          data-aos="fade-up"
          data-aos-offset="-200"
          data-aos-duration="700"
        >
          <div className="title">
          <h2>
  <span>Martley Strategy™</span> <br />
  <span className="highlight">What Drives Us</span>
</h2>

            <h4>Smart brand strategy for businesses ready to lead</h4>
          </div>
        </div>
      </div>
    </div>
  );
};