import React, { useEffect } from "react";
import AOS from "aos";
export const Disclaimer = () => {
  useEffect(() => {
    AOS.init({
      duration: 3000,
      mirror: true,
    });
  }, []);
  return (
    <div
      className="disclaimer"
      data-aos="fade-right"
      data-aos-offset="-300"
      data-aos-duration="700"
    >
      <div className="title">
        <h2>
          <span className="type1">Why Clients</span>
          <br />
          <span className="type2">Choose Us</span>
        </h2>
      </div>
      <div className="sub-title">
        {/* <h4>Compliments is our cup of tea,<br/> Criticism is our energy drink</h4> */}
        <h4>We’re proud of the work we do and even prouder of the results our clients share.</h4>
      </div>
      <div className="sub-text">
        <qoute>
        Clients come to us for clarity and stay for results.
        </qoute>
        {/* //<p>*Some surnames and photos are hidden as per Client&apos;s request</p> */}
      </div>
    </div>
  );
};
